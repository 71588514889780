import React, { Component } from 'react'
import Link from 'next/link'
import Page from './Page'

export default class SystemStatus extends Component {
  state = {
    status: 'OK',
    events: [],
  }

  fetchSystemStatus = async () => {
    try {
      const statusReq = await window.fetch('/status.json')
      if (statusReq.status === 200) {
        const systemStatus = await statusReq.json()
        this.setState({
          status: systemStatus.status,
          events: systemStatus.events,
        })
      }
    } catch (err) {}
  }

  componentDidMount() {
    // TODO: Re-enable fetchSystemStatus - need to wrap window.fetch to
    // deal with lousy browsers
    // this.fetchSystemStatus()
  }

  render() {
    const unresolvedEvents = this.state.events.filter(e => !e.resolved)

    if (this.props.fullPage) {
      return (
        <Page className="SystemStatus">
          <h2>STATUS</h2>
          {this.state.events.map(event => (
            <div key={event.date} className="event">
              <h3>{event.title}</h3>
              <h4>
                {event.date}
                {
                  <span className={`status ${event.resolved ? 'resolved' : ''}`}>
                    {event.resolved ? <span>RESOLVED</span> : <span>INVESTIGATING</span>}
                  </span>
                }
              </h4>
              <p>{event.body}</p>
            </div>
          ))}
        </Page>
      )
    } else {
      if (unresolvedEvents.length === 0 || this.state.status === 'OK') return null

      return (
        <div className={`SystemStatus toast ${unresolvedEvents[0].type || 'error'}`}>
          <div>{unresolvedEvents[0].title}</div>
          <div className="more">
            <Link href="/status">more</Link>
            <a className="close" onClick={() => this.setState({ status: 'OK' })}>
              close
            </a>
          </div>
        </div>
      )
    }
  }
}
